@tailwind base;
@tailwind components;
@tailwind utilities;

/* global styles go here */
body,
html,
#__next {
  color: theme("colors.gray.DEFAULT");
}

.page {
  min-height: 100dvh;
  overflow-x: hidden;
}

.page footer {
  top: 100dvh;
  position: sticky;
}

/* Logo */
.footer-logo .logo-leaf,
.footer-logo .logo-stem,
.footer-logo .logo-chnk,
.footer-logo .logo-behavioral-health {
  fill: white;
}
/* End Logo */

/* CTA Banner */
.page,
.page-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cta-banner-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.leaves-decor::before {
  content: url("public/assets/footer-leaves.svg");

  @apply absolute -left-16 -top-20 -scale-x-50 scale-y-50 lg:-top-8 lg:left-0 lg:-scale-x-100 lg:scale-y-100;
}
.leaves-decor::after {
  content: url("public/assets/footer-leaves.svg");

  @apply absolute -bottom-14 -right-16 scale-50 lg:-top-8 lg:right-0 lg:scale-100;
}
.brush-decor::before {
  content: url("public/assets/cta-brush-left.svg");

  @apply absolute -left-44 -top-8 z-0 h-full scale-75 xl:left-0 xl:top-0 xl:scale-100;
}

@screen xl {
  .brush-decor::after {
    content: url("public/assets/cta-brush-right.svg");

    @apply absolute right-0 top-0 scale-100;
  }
}
/* End CTA Banner */

*:focus-visible {
  outline-color: theme("colors.orange");
  outline-style: solid;
  outline-width: 2px;
}

/* Text Styles */
h1 {
  @apply text-xl2 lg:text-xl3;
}
h2 {
  @apply text-l3 lg:text-xl1;
}
h3 {
  @apply text-l1 lg:text-l2;
}
h4 {
  @apply text-l1 lg:text-l2;
}
h5 {
  @apply text-m2 lg:text-l1;
}
h6 {
  @apply text-m1 lg:text-m2;
}
p {
  @apply pb-4 text-base last:pb-0;
}

.prose h1 {
  @apply text-xl2 lg:text-xl3;
}
.prose h2 {
  @apply text-l3 lg:text-xl1;
}
.prose h3 {
  @apply text-l1 lg:text-l2;
}
.prose h4 {
  @apply text-l1 lg:text-l2;
}
.prose h5 {
  @apply text-m2 lg:text-l1;
}
.prose h6 {
  @apply text-m1 lg:text-m2;
}

:is(h1, h2, h3) {
  font-family: theme("fontFamily.primary");
}
:is(h4, h5, h6) {
  font-family: theme("fontFamily.secondary");
}

p {
  font-family: theme("fontFamily.tertiary");
}

/* End Text Styles */

/* Begin Button Styles */
@layer components {
  .primary-link {
    @apply rounded-full border-[3px] border-orange bg-orange px-9 py-3 text-center font-secondary text-m3 font-bold uppercase text-white no-underline hover:border-green hover:bg-green hover:text-teal-dark;
  }

  .secondary-link {
    @apply whitespace-nowrap rounded-full border-[3px] border-white px-9 py-3 text-center font-secondary text-m3 font-bold uppercase text-white no-underline hover:bg-white hover:text-teal-dark;
  }

  .tertiary-link {
    @apply whitespace-nowrap rounded-full border-[3px] border-orange px-9 py-3 text-center font-secondary text-m3 font-bold uppercase text-orange no-underline hover:bg-orange hover:text-white;
  }

  .small-link {
    @apply rounded-full border-[3px] border-orange bg-orange px-6 text-center text-m1 font-bold uppercase text-white no-underline hover:border-green hover:bg-green hover:text-teal-dark;
  }

  .inline-link {
    @apply text-base font-medium text-teal-dark underline hover:no-underline;
  }
}
/* End Button Styles */

/* Main Menu */
/* Header */
/* Current Page */
.top-level-menu ul li ul li .current-page {
  text-decoration: underline;
}

/* Top Level Menu Items */
.top-level-menu > ul > li {
  color: theme("colors.teal.dark");
  font-weight: 600;
  font-family: theme("fontFamily.primary");
  font-size: theme("fontSize.m2");

  @apply lg:my-0 lg:mr-6 xl:mr-8;
}

.top-level-menu ul li a,
.top-level-menu ul li .nonlinked {
  width: max-content;
}
.top-level-menu > ul > li > a::after,
.top-level-menu > ul > li > .nonlinked::after {
  content: url("public/assets/chevron-down.svg");
  display: inline-block;

  @apply ml-2 transition-all duration-500;
}

.top-level-menu > ul > li > a.current-open-menu::after,
.top-level-menu > ul > li > .nonlinked.current-open-menu::after {
  @apply rotate-180;
}

.top-level-menu > ul > li > a:hover,
.top-level-menu > ul > li > .nonlinked:hover,
.top-level-menu > ul > li > a.current-open-menu,
.top-level-menu > ul > li > .nonlinked.current-open-menu {
  cursor: pointer;
  text-decoration: underline theme("colors.green") theme("spacing.1");
  text-underline-offset: theme("spacing[1.5]");
}

/* Submenu Items */
.submenu {
  position: absolute;
  background: url("public/assets/menu-waves.svg") no-repeat theme("colors.springWood");
  background-size: 300dvw auto;
  background-position: center;
  font-family: theme("fontFamily.primary");
  width: 100%;
  padding: 0px theme("spacing.4");
  height: 85dvh;
  z-index: 100;
  padding-bottom: theme("spacing.40");
  overflow-y: scroll;
  overflow-x: hidden;

  @apply bg-top transition-all duration-300 lg:h-auto lg:overflow-hidden lg:bg-[length:100%,500%] lg:pb-0;
}

.submenu:has(.closed-whole-submenu),
.submenu > ul > li:has(ul > li.open-third-submenu + .third-level-items) > .basis-1\/3 > a,
.submenu > ul > li:has(ul > li.open-third-submenu + .third-level-items) > .basis-1\/3 > .nonlinked,
.submenu > ul > li.closed-second-submenu > ul,
.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > a::after,
.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > .nonlinked::after,
.submenu > ul > li > ul:has(.open-third-submenu) > li.closed-third-submenu {
  display: none;

  @apply lg:block;
}

.submenu > ul {
  max-width: theme("screens.xl");
  margin: 0px auto;
  @apply transition-all duration-300;
}

.submenu:has(ul > li.open-second-submenu) {
  background-position: -100dvw top;

  @apply lg:bg-left-top;
}
.submenu:has(ul > li > ul > li.open-third-submenu) {
  background-position: -200dvw top;

  @apply lg:bg-left-top;
}
.submenu > ul:has(li.open-second-submenu) {
  transform: translateX(-100%);

  @apply lg:translate-x-0;
}
.submenu > ul:has(li.open-second-submenu) > li.closed-second-submenu {
  display: none;
}

.submenu > ul > li > ul li a:hover,
.submenu > ul > li > ul li .nonlinked:hover,
.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > a,
.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > .nonlinked {
  @apply lg:text-teal-dark lg:underline;
}

.submenu > ul > li {
  width: 100%;
  padding: 12px 0px;
  max-height: 800px;
  border-bottom: 1px solid theme("colors.gray.mid");

  @apply first:pt-6 last:border-b-0 lg:flex lg:origin-top lg:border-b-0 lg:py-12 lg:transition-all lg:duration-500 lg:ease-in-out lg:first:pt-12;
}

.submenu > ul:has(li > ul > li.open-third-submenu + .third-level-items) {
  transform: translateX(-200%);
  @apply lg:translate-x-0;
}

.submenu > ul > li.open-second-submenu {
  border-bottom: 0px;
}

.submenu > ul > li.closed-whole-submenu {
  @apply lg:max-h-0 lg:py-0 lg:opacity-0;
}

.submenu > ul > li > .basis-1\/3 {
  position: relative;

  @apply lg:border-r lg:border-r-gray-mid lg:pr-6 lg:hover:cursor-auto;
}

.submenu > ul > li > .basis-1\/3 > a,
.submenu > ul > li > .basis-1\/3 > .nonlinked {
  font-size: theme("fontSize.m2");
  font-weight: 600;
  color: theme("colors.teal.dark");
  width: calc(100dvw - theme("spacing.16"));
  cursor: pointer;

  @apply px-4 lg:w-auto lg:px-0 lg:text-xl1 lg:hover:cursor-auto;
}

.submenu > ul > li > .basis-1\/3 > a::after,
.submenu > ul > li > .basis-1\/3 > .nonlinked::after {
  content: "";
  background-image: url("public/assets/chevron-right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  padding-left: 12px;
  width: 8px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
  @apply transition-all duration-300 lg:hidden;
}
.submenu > ul > li:hover > .basis-1\/3 > a::after,
.submenu > ul > li:hover > .basis-1\/3 > .nonlinked::after {
  right: -12px;
}
.submenu > ul > li.open-second-submenu > .basis-1\/3 > a::after,
.submenu > ul > li.open-second-submenu > .basis-1\/3 > .nonlinked::after {
  display: none;
}

.submenu > ul > li.open-second-submenu > .basis-1\/3 > a::before,
.submenu > ul > li.open-second-submenu > .basis-1\/3 > .nonlinked::before,
.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > a::before,
.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > .nonlinked::before {
  content: "";
  background-image: url("public/assets/chevron-right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 8px;
  height: 16px;
  display: inline-block;
  transform: rotate(180deg);
  left: 0;
  margin-right: 12px;
  @apply transition-all duration-300 lg:hidden;
}

.submenu > ul > li.open-second-submenu > .basis-1\/3 > a:hover::before,
.submenu > ul > li.open-second-submenu > .basis-1\/3 > .nonlinked:hover::before,
.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > a:hover::before,
.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > .nonlinked:hover::before {
  left: -12px;
}

/* Second Level Submenu Items */
.submenu > ul > li > ul {
  position: relative;
}

.submenu > ul > li.open-second-submenu,
.submenu > ul > li > ul > li.open-third-submenu,
.submenu > ul > li > ul > li.open-third-submenu + .third-level-items {
  transform: translateX(100%);

  @apply lg:translate-x-0;
}

.submenu > ul > li > ul > li {
  position: relative;
  padding: theme("spacing.3") theme("spacing.8");
  border-bottom: 1px solid theme("colors.gray.mid");

  @apply leading-8 first:mt-2 first:border-t first:border-t-gray-mid lg:border-0 lg:pt-0 lg:first:border-t-0;
}

.submenu > ul > li > ul > li.open-third-submenu {
  padding: theme("spacing.3") theme("spacing.0");
  white-space: nowrap;

  @apply leading-8 lg:px-8 lg:pt-0;
}

.submenu > ul > li > ul > li > .basis-1\/3 > a,
.submenu > ul > li > ul > li > .basis-1\/3 > .nonlinked {
  font-weight: 400;
  font-size: theme("fontSize.m1");
}

.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > a,
.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > .nonlinked {
  font-size: theme("fontSize.m2");
  font-weight: 600;
  color: theme("colors.teal.dark");
  width: calc(100dvw - theme("spacing.16"));
  cursor: pointer;

  @apply px-4 lg:w-auto lg:px-0 lg:text-m1 lg:font-normal;
}

.submenu > ul > li > ul > li:has(+ .third-level-items) > .basis-1\/3 > a::after,
.submenu > ul > li > ul > li:has(+ .third-level-items) > .basis-1\/3 > .nonlinked::after {
  content: "";
  background-image: url("public/assets/chevron-right-gray.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  padding-left: 12px;
  width: 8px;
  height: 16px;
  top: 50%;
  transform: translateY(-62%);
  @apply transition-all duration-300;
}
.submenu > ul > li > ul > li:has(+ .third-level-items):hover > .basis-1\/3 > a::after,
.submenu > ul > li > ul > li:has(+ .third-level-items):hover > .basis-1\/3 > .nonlinked::after {
  right: -10px;
}

.submenu > ul > li > ul > li:has(+ .third-level-items):hover > .basis-1\/3 > a::after,
.submenu > ul > li > ul > li:has(+ .third-level-items):hover > .basis-1\/3 > .nonlinked::after,
.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > a::after,
.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > .nonlinked::after {
  background-image: url("public/assets/chevron-right.svg");
}

.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > a::after,
.submenu > ul > li > ul > li.open-third-submenu > .basis-1\/3 > .nonlinked::after {
  transform: translate(0px, -62%) rotate(180deg);
  right: -10px;
}

/* Third Level Submenu Items */
.submenu > ul > li > ul > .third-level-items {
  position: relative;
  display: none;
  font-weight: 400;
  font-size: theme("fontSize.m1");
  width: 100%;
  min-height: 100%;
  pointer-events: none;

  @apply transition-[left,opacity] duration-300 lg:absolute lg:left-0 lg:top-0 lg:block lg:w-[120%] lg:border-l lg:border-gray-mid lg:opacity-0;
}

.submenu > ul > li > ul > .third-level-items > ul > li {
  @apply first:mt-0;
}
.submenu > ul > li > ul > .third-level-items > ul > li > .basis-1\/3 > a,
.submenu > ul > li > ul > .third-level-items > ul > li > .basis-1\/3 > .nonlinked {
  white-space: normal;

  @apply leading-8;
}

.submenu > ul > li > ul > li.open-third-submenu + .third-level-items {
  display: block;
  pointer-events: all;

  @apply lg:left-[110%] lg:opacity-100;
}

.submenu > ul > li > ul > .third-level-items > ul > li {
  padding: theme("spacing.3") theme("spacing.8");
  border-bottom: 1px solid theme("colors.gray.mid");
  white-space: normal;
  height: 100%;

  @apply lg:border-0 lg:pt-0 lg:first:mt-2 lg:first:border-t-0;
}

/* mobile menu button */
.mobile-menu-btn rect {
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all 0.3s ease-in-out;
}

.mobile-menu-btn.open .top-line {
  transform: rotate(45deg);
  transform-origin: center top;
  x: 25px;
  y: 14px;
}

.mobile-menu-btn.open .middle-line {
  opacity: 0;
}

.mobile-menu-btn.open .bottom-line {
  transform: rotate(-45deg);
  transform-origin: center top;
  x: -6px;
  y: 12.5px;
}

/* End mobile menu button */

/* Call to Action Sub Menu */
.cta-sub-menu::after {
  content: "";
  mask: url("public/assets/chevron-down.svg") no-repeat 50% 50%;
  mask-size: cover;
  -webkit-mask: url("public/assets/chevron-down.svg") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  display: inline-block;

  background-color: theme("colors.teal.dark");

  width: 15px;
  height: 11px;

  @apply relative top-[8px] rotate-180 transition-transform duration-300 lg:rotate-0 lg:duration-700 lg:hover:rotate-180 lg:hover:bg-green;
}

.cta-sub-menu.open::after {
  @apply rotate-0;
}
/* End Call to Action Sub Menu */
/* End Header */

/* Modal Component */
.modal-x:hover .modal-x-bg {
  @apply fill-gray;
}

.modal-x:hover .modal-x-line {
  @apply fill-yellow;
}

/* End Modal Component */

/* Photo Card & Featured List */
.photo-card a {
  @apply font-secondary text-m1 font-semibold;
}

.featured-list ul {
  list-style: none;
}

.featured-list li {
  position: relative;
  max-width: theme(spacing.60);
  margin: theme(spacing.2) 0;
}

.featured-list li:before {
  content: url("/assets/orange-chevron-bullet.svg");
  position: absolute;
  top: 0.125rem;
  left: -1.65rem;
}

.featured-list {
  container-type: inline-size;
  container-name: featuredList;
  max-width: unset;
}

@container featuredList (min-width: 450px) {
  .featured-list ul {
    columns: 2;
    column-gap: 2rem;
  }

  .featured-list ul li:first-child {
    margin-top: 0;
  }
}

/* End Featured List */

.faq-section-content p {
  @apply px-4 py-6;
}

/* Info Box */
.info-box.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  @apply text-white;
}

.sidebar-content.bg-teal .inline-link {
  @apply text-white;
}

.sliderAnimation___300FY {
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform;
}

.carousel__inner-slide button img {
  height: 72px;
}

/* Location Page */
.location-address {
  min-height: 43px;
}

.location-address:before {
  content: url("/assets/map-marker.svg");
  position: absolute;
  top: 0;
  left: 0;
}

/* Teal Scrollbar */
.teal-scrollbar {
  scrollbar-width: auto;
  scrollbar-color: theme("colors.teal.dark") theme("colors.gray.light");
}

/* Chrome, Edge, and Safari */
.teal-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.modal::-webkit-scrollbar,
.modal ::-webkit-scrollbar,
.location-selector__states::-webkit-scrollbar {
  width: 6px;
}

.teal-scrollbar::-webkit-scrollbar-thumb {
  background-color: theme("colors.teal.dark");
  border-radius: 16px;
  border: 3px solid transparent;
}
/* End Teal Scrollbar */

/* Card Section Decoration */
.dots-decoration::after {
  content: url("public/assets/dots.svg");
  position: absolute;
  left: -15rem;
  top: -9rem;

  @apply sm:-right-20 sm:left-auto sm:translate-y-0;
}

.dots-decoration::after g {
  opacity: 0;
}

.dots-decoration::after g {
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.dots-decoration::after #Group_1 {
  animation-delay: 250ms;
}

.dots-decoration::after #Group_2 {
  animation-delay: 500ms;
}

.dots-decoration::after #Group_3 {
  animation-delay: 750ms;
}

.dots-decoration::after #Group_4 {
  animation-delay: 1s;
}

.dots-decoration::after #Group_5 {
  animation-delay: 1.25s;
}

.dots-decoration::after #Group_6 {
  animation-delay: 1.5s;
}

.dots-decoration::after #Group_7 {
  animation-delay: 1.75s;
}

.dots-decoration::after #Group_8 {
  animation-delay: 2s;
}

/* End Card Section Decoration */

/* Program Overview */
.program-overview::after {
  content: url("public/assets/dots.svg");
  position: absolute;
  left: 0;
  bottom: 0;
  transform: rotate(-60deg);

  @apply lg:-right-80 lg:-top-96 lg:bottom-auto lg:left-auto lg:-rotate-12;
}

.program-overview-heading {
  display: flex;
  align-items: center;
}

@screen lg {
  .program-overview-heading::after {
    content: "";
    height: 1px;
    flex: 1;
    margin-left: theme("spacing.16");
    background-color: theme("colors.teal.dark");
  }
}
/* End Program Overview */

@screen lg {
  .featured-team-member {
    display: grid;
    gap: 0rem 1.25rem;
    grid-template-areas:
      "member heading"
      "member content";
  }

  .featured-team-member h2 {
    grid-area: heading;
  }
  .featured-team-member .team-member {
    grid-area: member;
  }
  .featured-team-member .featured-team-member-content {
    grid-area: content;
  }
}

/* Begin Search Page */
.pagefind-ui {
  @apply relative text-teal-dark;
}

.pagefind-ui__search-input {
  @apply block w-full rounded-none rounded-br-xl border-2 border-teal-dark py-2 pl-9 pr-2 font-tertiary transition-all placeholder:italic placeholder:text-teal-dark;
}

.pagefind-ui__form::before {
  content: "";
  background: url("public/assets/magnifying-glass.svg") no-repeat;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  @apply left-2 top-[11px] z-50 text-teal-dark;
}

.pagefind-ui__message {
  @apply my-10 pb-0 font-primary text-xl2 lg:text-xl3;
}

.pagefind-ui__search-clear {
  position: absolute;
  right: 1rem;
  top: 0.6rem;
}

.pagefind-ui__result-link {
  @apply font-primary text-l2 font-medium hover:underline;
}

.pagefind-ui__result-excerpt {
  @apply mb-10 mt-0 text-base text-gray;
}

.pagefind-ui mark {
  @apply rounded-md bg-white font-primary text-orange;
}

.pagefind-ui__button {
  @apply primary-link;
}

/* End Search Page */
.supernova {
  @apply bg-springWood;
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

.fade-in g {
  opacity: 0;
}

.fade-in g {
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.fade-in #Group_1 {
  animation-delay: 250ms;
}

.fade-in #Group_2 {
  animation-delay: 500ms;
}

.fade-in #Group_3 {
  animation-delay: 750ms;
}

.fade-in #Group_4 {
  animation-delay: 1s;
}

.fade-in #Group_5 {
  animation-delay: 1.25s;
}

.fade-in #Group_6 {
  animation-delay: 1.5s;
}

.fade-in #Group_7 {
  animation-delay: 1.75s;
}

.fade-in #Group_8 {
  animation-delay: 2s;
}

@keyframes slide-in {
  to {
    transform: translate(0px, 0px);
  }
}

.lines {
  animation-name: slide-in;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transform: translate(-24px, 240px);
}

@keyframes icon-card-slide-in {
  to {
    transform: translate(-50%, 0px);
  }
}

.icon-card-lines {
  animation-name: icon-card-slide-in;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transform: translate(-50%, 50%);
}

.image-fade-in-dots,
.image-fade-in-lines,
.image-fade-in-brush {
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 800ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.image-fade-in-dots,
.image-fade-in-brush {
  animation-delay: 1.75s;
}

.image-fade-in-lines {
  animation-delay: 500ms;
}
